import {javascript} from "@api/index";
import {useHydration} from "@stores/utils";
import {defineStore} from "pinia";
import {ref} from "vue";

export type UserStoreType = ReturnType<typeof useUserStore>
export const allUserStores = ref<Array<UserStoreType>>([])

export const createUserStore = (uniqueId: string | number) => {
  const userStore = defineStore('user-store-' + String(uniqueId), () => {
    const hydration = useHydration<Backend.Models.User>(javascript.loggedUser)
    const hydrateById = (id: number, fields: Array<keyof Backend.Models.User> = []) => { return hydration.hydrate({  id }, fields) }
    allUserStores.value.push(userStore as unknown as UserStoreType)

    return {
      ...hydration,
      hydrateById,
      id: hydration.hydratedComputed('id'),
      displayName: hydration.hydratedComputed('display_name'),
    }
  })
  return userStore
}

export const useUserStore = createUserStore('current')